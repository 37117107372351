import * as React from "react"
import "/src/css/list.css"
import Block from "/src/components/header-002"
import BlockACC from "/src/components/call-to-action-006"


export default function Example() {
  return (
    <>
    <Block
      minH="500|0"
      branding={{
        name: "A1",
      }}
      links={[
        {
          title: "SOFTBALL HIGHLIGHTS",
          href: "/softball-highlights",
        },
        {
          title: "SCHEDULE",
          href: "/softball-schedule",
        },
        {
          title: "ACCOMPLISHMENTS",
          href: "/accomplishments-awards",
        },
        {
          title: "2ND SPORT WRESTLING",
          href: "/wrestling-highlights",
        },
      ]}
      buttonLink={{
        title: "NCAA ID# 2009918132",
        href: "#",
      }}
    />
    <BlockACC
      subheading="FALL SCHEDULE"
      heading="Events and Tournaments"
      text="Upcomomg 2023 Louisiana Tech Softball"      
      />
    
    </>
  )
}
