import * as React from "react";

export default function BlockACC({
  subheading,
  heading,
  text,
  buttons,
  ...props
}) {
  return (
    <section py="6|8|12|20" {...props}>
      <div variant="container">
        <div
          display="flex"
          flexDirection="column|row"
          justifyContent="space-between"
          alignItems="flex-start|center"
        >
          <div flex="1">
            {subheading && (
              <p color="primary" textTransform="uppercase" m="0">
                {subheading}
              </p>
            )}
            {heading && (
              <h1 variant="heading.h1" fontWeight="bolder" lineHeight="tight">
                {heading}
              </h1>
            )}
            {text && (
              <p variant="text.lead" mt="2">
                {text}
              </p>
            )}
          </div>
          
          <div className="calendar">
            <ul fontSize="xl" py="50">       
              <li><h1>OCTOBER 2023</h1></li>
              <li>10th vs ULM - HOME</li>
              <li>15th vs LSU- Alexandria HOME</li>
              <li>21st vs Grambling State AT Grambling, LA</li>
              <li>28th vs Jarvis Christian HOME</li>
              <li><h1>November 2023</h1></li>
              <li>2nd vs LSU-Eunice HOME</li>
            </ul>
            <a href="https://latechsports.com/sports/softball/schedule">Click here for up-to-date schedule</a>
          </div>
          
          {buttons}
        </div>
      </div>
    </section>
  );
}
